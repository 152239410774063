import {Button, Popconfirm, Select, Tag} from 'antd';
import {EditTwoTone, DeleteTwoTone, LinkOutlined} from '@ant-design/icons';
import {findNameById, selectFilterOption} from '@services/utils';
import moment from "moment/moment";
import {MessageText} from "@components/tables/documents/MessageText";
import {History} from "@components/tables/documents/History";

const {Option} = Select;

export const DocumentColumns = ({
                                    apiUpdate,
                                    changeDocument,
                                    types,
                                    statuses,
                                    setDocument_id,
                                    setAction,
                                    setIsVisible,
                                    deleteDocument,
                                    contragents,
                                    companies,
                                    openPopup,
                                    changeShowPopup,
                                    saveMsg,
                                    setHistoryView,
                                    historyView,
                                    history,
                                    document_id,
                                    updatedStatusCurrent,
                                    setUpdatedStatusCurrent
                                }) => {

    function prepareDate(date) {
        const dateObj = new Date(date);

        // Получаем день, месяц и год
        const day = String(dateObj.getDate()).padStart(2, '0');  // добавляем ведущий ноль
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');  // месяцы в JS начинаются с 0, поэтому добавляем 1
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`
    }

    return [
        {
            title: 'Компания',
            dataIndex: 'companyId',
            render: (_, {companyId}) => <h3>{companyId ? findNameById(companies, companyId) : ''}</h3>,
            // fixed: 'left',
        },

        {
            title: 'Документ',
            dataIndex: 'name',
            defaultSortOrder: null,
            sorter: true,
            render: (_, {id, name}) => (
                <h3>
                    <a
                        href="#"
                        className="table-link"
                        onClick={(e) => {
                            e.preventDefault();
                            setDocument_id(id);
                            setAction('update');
                            setIsVisible(true);
                        }}
                    >
                        {name}
                    </a>
                </h3>
            ),
            // fixed: 'left',
        },
        {
            title: 'Дата документа',
            dataIndex: 'date',
            defaultSortOrder: null,
            render: (_, {date}) => {
                if (!date) return null;
                let textDate = prepareDate(date)
                return <h3>{textDate}</h3>;
            },
            sorter: true,
        },
        {
            title: 'Тип',
            width: '15%',
            render: ({type, id}) => (
                <Select
                    onChange={async (updatedType) => {
                        await apiUpdate('documents', id, {type: updatedType});
                        changeDocument('type', updatedType, id);
                    }}
                    optionFilterProp="children"
                    filterOption={selectFilterOption}
                    value={type}
                    optionLabelProp="label"
                    suffixIcon={<i className="ant-menu-submenu-arrow"/>}
                >
                    {types.map(({id, name, color = null}) => (
                        <Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
                            <Tag color={color}>{name}</Tag>
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Контрагент',
            dataIndex: 'contragentId',
            defaultSortOrder: null,
            sorter: true,
            render: (_, {contragentId = null}) =>
                <h3>{contragentId ? findNameById(contragents, contragentId) : ''}</h3>,
        },
        {
            title: 'Статус',
            width: '15%',
            render: ({status, id, changeDate}) => {
                let textDate = null
                if (changeDate) {
                    textDate = prepareDate(changeDate)
                }
                return (
                    <div className='status-wrapper'>
                        <Select
                            onChange={async (updatedStatus) => {
                                setDocument_id(id)
                                setUpdatedStatusCurrent(updatedStatus)
                                let currentDate = new Date();
                                // let formattedDate = (currentDate.getDate()).toString().padStart(2, '0') + '-' +
                                // 	(currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' +
                                // 	currentDate.getFullYear();
                                let formattedDate = currentDate.getTime()
                                await changeShowPopup(true);
                                // await apiUpdate('documents', id, {status: updatedStatus, changeDate: formattedDate, message: ''});
                                changeDocument('status', updatedStatus, id);
                                changeDocument('changeDate', formattedDate, id);
                            }}
                            optionFilterProp="children"
                            filterOption={selectFilterOption}
                            value={status}
                            optionLabelProp="label"
                            suffixIcon={<i className="ant-menu-submenu-arrow"/>}
                        >
                            {statuses.map(({id, name, color = null}) => (
                                <Option key={id} name={name} value={id} label={<Tag color={color}>{name}</Tag>}>
                                    <Tag color={color}>{name}</Tag>
                                </Option>
                            ))}
                        </Select>
                        {
                            status === 2 && textDate && <div className='date-status'>
                                {textDate}
                            </div>
                        }
                        {
                            status === 3 && textDate && <div className='date-status'>
                                {textDate}
                            </div>
                        }
                        {openPopup &&
                            <MessageText apiUpdate={apiUpdate} id={document_id} updatedStatus={updatedStatusCurrent} />}
                    </div>
                )
            }
        },

        {
            title: 'История изменений',
            dataIndex: 'history',
            render: (_, {id}) => (
                <div className='history'>
                    <Button
                        className="btn-history"
                        onClick={() => {
                            setDocument_id(id)
                            setTimeout(() => {
                                setHistoryView(true)
                            }, 400)

                        }}
                    >
                        Посмотреть
                    </Button>
                    {history && historyView && <History id={id} history={history} setHistoryView={setHistoryView}/>}
                </div>
            ),
        },
        {
            title: 'Сумма, руб',
            dataIndex: 'total',
            render: (_, {total}) => <h3>{total.trim() ? total : ''}</h3>,
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            width: '140px',
            fixed: 'right',
            render: (_, {id, name, link}) => (
                <div className="icons-list">
                    {!!link && (
                        <a href={link} className="ant-btn" style={{marginRight: 5}} target="_blank" rel="noreferrer">
                            <LinkOutlined/>
                        </a>
                    )}
                    <Button
                        className="btn-edit"
                        onClick={() => {
                            setDocument_id(id);
                            setAction('update');
                            setIsVisible(true);
                        }}
                    >
                        <EditTwoTone twoToneColor="#52c41a"/>
                    </Button>
                    <Popconfirm
                        title={`Вы действительно хотите удалить документ ${name}?`}
                        placement="topRight"
                        onConfirm={() => deleteDocument(id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button className="btn-delete" style={{marginTop: 5}}>
                            <DeleteTwoTone twoToneColor="red"/>
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];
}

