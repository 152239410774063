import React, {useState} from "react";
import {changeOpenPopup} from "@store/reducers/documents";
import {useDispatch} from "react-redux";

export const History = ({history, id, setHistoryView}) => {
    const dispatch = useDispatch()
    const changeView = () => {
        setHistoryView(false)
    }

    function prepareDate(date) {
        const dateObj = new Date(date);

        // Получаем день, месяц и год
        const day = String(dateObj.getDate()).padStart(2, '0');  // добавляем ведущий ноль
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');  // месяцы в JS начинаются с 0, поэтому добавляем 1
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`
    }
    return (
        <section className="popup popupCall open">
            <div className="popup-body-2">
                <div className="modal">
                    <div className='modal-item'>
                        <span className='modal-item-header date-span'>Дата</span>
                        <span className='modal-item-header status-span'>Статус</span>
                        <div className='modal-item-header comment-elem'>Комментарий</div>
                    </div>
                    {history && history.map(item => {
                        let formattedDate = prepareDate(item.date)
                        return (
                            <div className='modal-item' key={item.id}>
                                <span className='date-span'>{formattedDate}</span>
                                <span className='status-span'>{item.status}</span>
                                {item.comment ? <div>{item.comment}</div> : <div>{'Нет комментария'}</div>}
                            </div>
                        );
                    })}
                </div>
                <button className='popup-btn' onClick={changeView}>Закрыть</button>
            </div>
        </section>
    );
}

