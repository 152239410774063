import React, {useState} from "react";
import {changeOpenPopup, documentsReducer} from '@store/reducers/documents';
import {useDispatch, useSelector} from 'react-redux';


export const MessageText = ({apiUpdate, id, updatedStatus }) => {
    const dispatch = useDispatch()
    const [msg, setMsg] = useState('')

    let currentDate = new Date();
    let formattedDate = currentDate.getTime()
    const closePopup = () => {
        dispatch(changeOpenPopup('text', msg));
        dispatch(changeOpenPopup('popup', false));
        apiUpdate('documents', id, {status: updatedStatus, changeDate: formattedDate, message: msg});
    }

    return (
        <section className="popup popupCall open">
            <div className="popup-body-1">
                <div className="modal">
                            <textarea
                                className=""
                                onChange={(val) => setMsg(val.target.value)}
                                rows="8"
                                name="message"
                                placeholder=" "></textarea>
                    <button onClick={closePopup}>Отправить</button>
                </div>
            </div>
        </section>
    )
}


